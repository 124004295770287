import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Container, Layout } from 'layout';

import { useCurrentLang } from 'hooks/useCurrentLang';

import { NotFoundPageQueryResult } from './models';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allNotFoundPage: { nodes },
    notFoundPage,
  }: NotFoundPageQueryResult = useStaticQuery(graphql`
    {
      allNotFoundPage {
        nodes {
          lang
          text
        }
      }
      notFoundPage {
        text
      }
    }
  `);

  const { text } = nodes.find((item) => item.lang === currentLangCode) || notFoundPage;

  return (
    <Layout>
      <section className="not-found container-wrapper">
        <div className="not-found__holder">
          <Container fluid className="not-found__content">
            <div className="not-found__content-text">
              <p>{text}</p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
